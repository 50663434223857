<template>
  <section
      v-if="isShow"
      :class="['modal', { 'modal_bottom': isBottomPosition, 'show': isShow }]"
      @click.self="hideModal"
      :style="{ backgroundColor: overlayColor }"
  >
    <div
        class="modal__body"
        @click.stop
        :style="{ transform: bodyTransform, transition: bodyTransition }"
    >
      <button class="cross" @click="hideModal"></button>
      <button class="chevron" @click="hideModal">
        <svg v-show="isBottomPosition" class="chevron__icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25
              16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608
              6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945
              2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064
              7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891
              9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831
              15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203
              7.16828 18.1887 6.85039 17.9543 6.61598Z"
              fill="#333333"
          />
        </svg>
        <svg v-show="!isBottomPosition" width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="close-button" d="M25 25L5 5M25 5L5 25" stroke-width="4" stroke-linecap="round"/>
        </svg>
      </button>
      <div
          ref="scrollContainer"
          class="modal__body-container_scroll"
          @touchstart="startSwipe($event)"
          @touchmove="swipe($event)"
          @touchend="stopSwipe"
      >
        <div class="swipe-block">
          <div class="swipe-block__line"></div>
        </div>
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import bodyState from '@helpers/bodyState'

export default {
  name: 'InitModal',
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    isBottomPosition: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      isSwiping: false,
      touchStart: 0,
      touchEnd: 0,
      overlayColor: 'rgba(0, 0, 0, 0.51)',
      bodyTransform: 'translate3d(0, 0, 0)',
      bodyTransition: '',
    }
  },
  methods: {
    hideModal() {
      this.bodyTransition = '0.6s ease'
      this.bodyTransform = `translate3d(0, ${window.innerHeight}px, 0)`
      this.overlayColor = 'rgba(0, 0, 0, 0)'
      setTimeout(() => this.closeModal(), 600) // Таймер для завершения анимации перед закрытием
    },
    closeModal() {
      this.$emit('close', false)
    },
    startSwipe($event) {
      if ($event.target.tagName === 'IMG' || this.$refs.scrollContainer.scrollTop) {
        return
      }
      this.touchStart = $event.targetTouches[0].clientY
      this.bodyTransition = ''
      this.isSwiping = true
    },
    swipe($event) {
      if (!this.isSwiping) return
      this.touchEnd = $event.targetTouches[0].clientY
      if (this.touchEnd > this.touchStart) {
        $event.preventDefault()
        const moveDistance = this.touchEnd - this.touchStart
        this.bodyTransform = `translate3d(0, ${moveDistance}px, 0)`
        this.updateOverlayOpacity(moveDistance)
      }
    },
    stopSwipe() {
      const DISTANCE_TO_CLOSE = 100
      if (this.touchEnd - this.touchStart > DISTANCE_TO_CLOSE) {
        this.hideModal()
      } else {
        this.resetModalStyles()
      }
      this.isSwiping = false
      this.touchStart = 0
      this.touchEnd = 0
    },
    resetModalStyles() {
      this.bodyTransition = '0.3s ease'
      this.bodyTransform = 'translate3d(0, 0, 0)'
      this.overlayColor = 'rgba(0, 0, 0, 0.51)'
    },
    updateOverlayOpacity(distance) {
      const opacity = Math.max(0, 0.6 - (distance / window.innerHeight) * 0.6)
      this.overlayColor = `rgba(0, 0, 0, ${opacity})`
    },
  },
  watch: {
    isShow(isOpen) {
      if (isOpen) {
        bodyState.blockBody()
        this.resetModalStyles()
      } else {
        bodyState.unblockBody()
      }
    },
  },
}
</script>
