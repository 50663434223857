<template>
  <init-modal
    class="address-modal"
    :is-show="isShow"
    :is-bottom-position="screenWidth <= 768"
    @close="closeModal"
  >
    <div class="address-modal__content">
      <h1 class="address-modal__title">
        Указать адрес
      </h1>
      <div class="address-modal__radio" v-show="showTypeDelivery && deliveryTypeRadioList.length > 1">
        <app-radio
          v-for="(radio, index) in deliveryTypeRadioList"
          :key="++index"
          :radio-data="radio"
          @change="changeDeliveryType"
        />
      </div>
      <div
        v-show="activeDeliveryType === DELIVERY_TYPES.COURIER"
        class="address-modal__input-button"
      >
        <app-autocomplete
            :is-create-order-pages="false"
            :show-messages="showDeliveryMessages"
            @loaded-address-click="handleLoadedAddressClick"
            v-model="fullStreetModalAddress"
        />
        <app-button
          :is-disabled="isDisabledButton"
          @click="chooseAddressCourier"
        >
          Доставить сюда
        </app-button>
      </div>
      <div
        v-show="activeDeliveryType === DELIVERY_TYPES.PICKUP"
        class="address-modal__input-button"
      >
        <app-select
          label="Откуда забрать*"
          @change="selectOption"
          pre-select-name="Откуда забрать*"
          :options="pickupPoints"
          :default-selected-option="defaultSelectedOption"
        />
        <app-button @click="chooseAddressPickup">
          Заберу отсюда
        </app-button>
      </div>
      <div class="address-modal__map">
        <iframe
          v-if="pickupPointsMapUrl && activeDeliveryType === DELIVERY_TYPES.PICKUP"
          id="pickup-iframe-map-modal"
          :src="pickupPointsMapUrl"
          width="100%"
          height="100%"
          scrolling="no"
          allow="geolocation"
        >
          Функционал выбора точки на карте не поддерживается в текущем браузере :(
        </iframe>
        <iframe
          v-if="deliveryRegionsMapUrl && activeDeliveryType === DELIVERY_TYPES.COURIER"
          :src="deliveryRegionsMapUrl"
          width="100%"
          height="100%"
          scrolling="no"
          allow="geolocation"
        >
          Функционал выбора точки на карте не поддерживается в текущем браузере :(
        </iframe>
      </div>
    </div>
  </init-modal>
</template>

<script>
import appRadio from '@ui/app-radio/appRadio.vue'
import appButton from '@ui/app-button/appButton.vue'
import appSelect from '@ui/app-select/appSelect.vue'
import initModal from '@ui/app-modals/init-modal/initModal.vue'
import AppAutocomplete from '@ui/app-autocomplete/appAutocomplete.vue'

import store from '@store'
import mutations from '@store/mutations'
import * as actions from '@store/types/actions-types'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { DELIVERY_TYPES } from '@/constants'
import fullStreetTemp from '@/mixins/fullStreetTemp'
import yml from '@config/parameters.yml'

export default {
  name: 'AddressModal',
  components: {
    AppAutocomplete,
    appButton,
    appSelect,
    initModal,
    appRadio,
  },
  mixins: [fullStreetTemp],
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    pickupPointsMapUrl: {
      type: String,
      required: false,
    },
    deliveryRegionsMapUrl: {
      type: String,
      required: false,
    },
    deliveryTypeRadioList: {
      type: Array,
    },
    activeDeliveryType: {
      type: String,
      default: yml.parameters['app.delivery_type'],
    },
    activeDelivery: {
      type: Object,
    },
    defaultSelectedOption: {
      type: Object,
    },
    showTypeDelivery: {
      type: Boolean,
      default: false,
      required: false,
    },
    showDeliveryMessages: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'close',
    'change-select',
    'set-order-delivery-type',
    'loaded-address-click',
  ],
  methods: {
    ...mapMutations({
      updateActivePickupPoint: mutations.UPDATE_PICKUP_POINTS_SELECTED,
    }),
    closeModal() {
      this.$emit('close')
    },
    changeDeliveryType(event) {
      this.$emit('set-order-delivery-type', event)
    },
    selectOption(event) {
      this.$emit('change-select', event)
      if (this.pricesByPickupPoints) {
        this.$store.commit('UPDATE_PRICES_IN_BASKET')
      }
    },
    chooseAddressPickup() {
      this.updateActivePickupPoint(this.activeDelivery)
      store.dispatch(actions.CHOOSE_ADDRESS_PICKUP)
      if (this.orderDetails.delivery.pickup.address) {
        this.closeModal()
      }
    },
    handleMessage(event) {
      if (event && typeof event.data === 'number') {
        const selectedPickupPoint = this.pickupPoints.find((point) => point.apiId === event.data)
        this.$emit('change-select', selectedPickupPoint)
      }
      if (event && event.type === 'message' && typeof event.data === 'string') {
        this.fullStreetModalAddress = event.data
      }
    },
    chooseAddressCourier() {
      store.dispatch(actions.CHOOSE_ADDRESS_COURIER)
      if (this.orderDetails.delivery.address.fullStreet) {
        this.closeModal()
      }
    },
    handleLoadedAddressClick(event) {
      this.$emit('loaded-address-click', event)
    },
  },
  computed: {
    ...mapState({
      pickupPoints: state => state.pickupPoints,
      screenWidth: state => state.screenWidth,
      orderDetails: state => state.orderDetails,
      deliveryAddress: state => state.deliveryAddress,
      pricesByPickupPoints: state => state.pricesByPickupPoints,
    }),
    ...mapGetters({
      isNullDeliveryPrice: 'isNullDeliveryPrices',
    }),
    DELIVERY_TYPES() {
      return DELIVERY_TYPES
    },
    isCorrectAddress() {
      return this.deliveryAddress.address.house.length > 0 && this.deliveryAddress.address.street.length > 0
    },
    isDisabledButton() {
      return this.isNullDeliveryPrice || !this.isCorrectAddress
    },
  },
  mounted() {
    window.addEventListener('message', this.handleMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
  },
}
</script>
